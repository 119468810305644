import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const products = [
  {
    image: '/product_images/1.png', // Add the correct image path
    title: 'PP White',
    code: 'Code: WL-1'
  },
  {
    image: '/product_images/2.png', // Add the correct image path
    title: 'PP Clear',
    code: 'Code: CL-1'
  },
  {
    image: '/product_images/3.png', // Add the correct image path
    title: 'Chrome Art',
    code: 'Code: CP-1'
  },
  {
    image: '/product_images/4.png', // Add the correct image path
    title: 'Maplitho',
    code: 'Code: ML-01'
  },
  {
    image: '/product_images/5.png', // Add the correct image path
    title: 'Mirrot Cote',
    code: 'Code: MC-01'
  },
  {
    image: '/product_images/6.png', // Add the correct image path
    title: 'Pet White Poly',
    code: 'Code: BP-01'
  },
  {
    image: '/product_images/7.png', // Add the correct image path
    title: 'PVC Colors',
    code: 'Code: RP-01'
  },
  {
    image: '/product_images/8.png', // Add the correct image path
    title: 'Polyster Film',
    code: 'Code: MC-01'
  },
  {
    image: '/product_images/9.png', // Add the correct image path
    title: 'Synthetic Paper',
    code: 'Code: SP-01'
  },
  {
    image: '/product_images/10.png', // Add the correct image path
    title: 'Aluminium Foil',
    code: 'Code: FA-01'
  },
  {
    image: '/product_images/11.png', // Add the correct image path
    title: 'Gold/Silver Paper',
    code: 'Code: FC-01'
  },
  {
    image: '/product_images/12.png', // Add the correct image path
    title: 'Thermal Transfer',
    code: 'Code: TT-01'
  },
  {
    image: '/product_images/13.png', // Add the correct image path
    title: 'B.O.P.P. Film',
    code: 'Code: BP-01'
  },
];

export default function LabelStockProducts() {
  return (
    <section className="py-20 bg-gray-30">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h1 className="text-6xl font-bold text-[#0d2251] mb-4">Label Stock</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="h-[200px] w-full flex items-center justify-center overflow-hidden rounded-md mb-4">
                <img
                  src={product.image}
                  alt={product.title}
                  className="object-contain h-full w-full"
                />
              </div>
              <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                <h3 className="text-xl font-semibold text-[#0d2251] mb-2 text-center">
                  {product.title}
                </h3>
                <h6 className="text-xl font-semibold text-[#ff0e18] mb-2 text-center">
                  {product.code}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
